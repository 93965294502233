<template>
  <el-dialog
    :modal="false"
    title="订单退款"
    :visible.sync="showDialog"
    width="600px"
    top="17vh"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="订单金额:" prop="">
          <el-input v-model="form.order_money" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="退款:" prop="">
          <el-input v-model="form.return_amount"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 15px">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { orderReturn, checkOrder } from '@/api/admin/userManagement'

  export default {
    name: 'ReturnMoney',
    components: {},
    data() {
      return {
        showDialog: false,
        form: {
          id: '',
          order_money: '',
          return_amount: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.form = {
            id: '',
            order_money: '',
            return_amount: '',
          }
        } else {
          this.money()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      money() {
        checkOrder({ id: this.form.id }).then((res) => {
          this.form.order_money = res.data.detail.filter(
            (i) => i.name == '合计'
          )[0].money
        })
      },
      handleSubmit() {
        console.log('1')
        orderReturn(this.form).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
