<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="选择客户"
      top="5vh"
      :visible.sync="showDialog"
      width="1050px"
      @close="close"
    >
      <div v-loading="loading" class="wrapper">
        <el-form
          ref="form"
          label-width="80px"
          :inline="true"
          style="margin-bottom: 10px"
        >
          <el-form-item>
            <el-input
              v-model="queryForm.keyword"
              placeholder="输入关键词"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-select
              v-model="queryForm.area_id"
              clearable
              style="width: 140px"
              placeholder="地区"
            >
              <el-option
                v-for="(i, idx) in areaSelect"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryForm.vest_in"
              clearable
              placeholder="归属人"
            >
              <el-option
                v-for="item in vestList"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryForm.channel_id"
              clearable
              placeholder="行业"
            >
              <el-option
                v-for="item in queryForm.channel"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="margin: 0 10px"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>

        <el-table
          stripe
          :data="list"
          height="450px"
          @selection-change="setSelectRows"
          @cell-click="cellClick"
        >
          <!-- 选择框 -->
          <!-- <el-table-column
          align="center"
          type="selection"
          width="60"
        ></el-table-column> -->
          <!-- 序号 -->
          <!-- <el-table-column prop="" align="center" label="序号" min-width="50px">
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="dealer_name"
            align="center"
            label="客户名称"
            width=""
          ></el-table-column>
          <el-table-column
            prop="boss"
            align="center"
            label="联系人"
            width=""
          ></el-table-column>
          <el-table-column
            prop="mobile"
            align="center"
            label="电话"
            width=""
          ></el-table-column>
          <el-table-column
            prop="sheng"
            align="center"
            label="地区"
            width=""
          ></el-table-column>
        </el-table>
        <el-pagination
          :current-page="queryForm.pageNo"
          :layout="layout"
          :page-size="queryForm.pageSize"
          :total="totalCount"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
        <!-- 底部按钮 -->
        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 5px; text-align: right"
        >
          <!-- <el-button type="primary" @click="save">确认</el-button> -->
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    areaSelectList,
    guishuSelectList,
    formalUserList,
  } from '@/api/admin/userManagement.js'
  export default {
    name: 'ChooseClient',
    data() {
      return {
        showDialog: false,
        loading: true,
        areaSelect: [],
        vestList: [],
        form: {
          area: [],
          channel: [],
        },
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
        // 表格
        layout: 'total, sizes, prev, pager, next, jumper',
        totalCount: 0,

        queryForm: {
          keyword: '',
          area_id: '',
          vest_in: '',
          pageNo: 1,
          pageSize: 10,
        },
        list: [],
      }
    },
    watch: {
      // filterText(val) {
      //   this.$refs.tree.filter(val)
      // },
      showDialog(val) {
        if (val) {
          this.fetchData()
          // this.getSelectList()
        } else {
          this.queryForm = {
            keyword: '',
            area_id: '',
            vest_in: '',
            pageNo: 1,
            pageSize: 10,
          }
          this.loading = true
        }
      },
    },
    mounted() {
      this.initAreaSelect()
      this.initVestSelect()
    },
    methods: {
      async initAreaSelect() {
        let { data } = await areaSelectList()
        this.areaSelect = data
      },
      async initVestSelect() {
        let { data } = await guishuSelectList({ pageSize: -1 })
        this.vestList = data
      },
      async fetchData() {
        let { data, code, msg, totalCount } = await formalUserList(
          this.queryForm
        )
        console.log(data, msg)
        this.list = data.rows
        this.totalCount = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData(this.queryForm)
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData(this.queryForm)
      },

      close() {
        this.showDialog = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      treeClick(val) {
        console.log(val)
      },
      // save() {
      //   this.showDialog = false
      // },
      setSelectRows() {},
      cellClick(row) {
        console.log(row)
        this.$emit('change-client', row)
        this.showDialog = false
      },
      add() {},
      handleQuery() {
        this.queryForm.pageNo = 1
        this.queryForm.pageSize = 10
        this.fetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tit {
    padding: 0 0 10px 0;
    text-align: center;
  }
</style>
