<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      title="软件服务合同"
      :visible.sync="showDialog"
      width="800px"
      top="8vh"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div v-loading="loading" class="orderTest-container">
        <order-status :status="status"></order-status>
        <el-form
          ref="form"
          :model="form"
          label-position="right"
          label-width="80px"
        >
          <el-form-item label="甲方:" prop="dealer_name_jia">
            {{ form.dealer_name_jia }}
          </el-form-item>
          <el-form-item label="乙方:" prop="dealer_name_yi">
            {{ form.dealer_name_yi }}
          </el-form-item>
          <el-form-item label="内容:" prop="content">
            {{ form.content }}
          </el-form-item>
        </el-form>
        <el-table stripe :data="list">
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 10px; text-align: right">
        <el-button type="primary">打印</el-button>
        <el-button type="warning">导出</el-button>
        <el-button v-if="statusid == 1" @click="handleReturn">退款</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
      <return-money ref="returnMoney" @refresh="indexRefresh"></return-money>
    </el-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { checkOrder } from '@/api/admin/userManagement'
  import OrderStatus from '@/baseComponents/orderStatus'
  import ReturnMoney from './returnMoney'
  export default {
    name: '',
    components: { OrderStatus, ReturnMoney },
    data() {
      return {
        id: 0,
        statusid: 1,
        status: '', //状态
        showDialog: false,
        form: {},
        list: [],
        loading: false,
        checkList: ['服务项目', '数量', '赠送', '金额'],
        columns: [
          {
            order: 1,
            label: '服务项目',
            prop: 'name',
            width: '',
          },
          {
            order: 2,
            label: '数量',
            prop: 'num',
            width: '',
          },
          {
            order: 3,
            label: '赠送',
            prop: 'gift',
            width: '',
          },
          {
            order: 4,
            label: '金额',
            prop: 'money',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await checkOrder({ id: this.id })
        this.form = data
        this.list = data.detail
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      indexRefresh() {
        this.statusid = 3
        this.$emit('refresh')
      },
      handleReturn() {
        this.$refs.returnMoney.form.id = this.id
        console.log(this.list.filter((i) => i.name == '合计')[0].money)
        this.$refs.returnMoney.form.order_money = this.list.filter(
          (i) => i.name == '合计'
        )[0].money
        this.$refs.returnMoney.showDialog = true
        // orderReturn({})
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 10px !important;
  }
</style>
