var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "订单退款",
        visible: _vm.showDialog,
        width: "600px",
        top: "17vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单金额:", prop: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.order_money,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "order_money", $$v)
                      },
                      expression: "form.order_money",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "退款:", prop: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.return_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "return_amount", $$v)
                      },
                      expression: "form.return_amount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }