var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "area_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "地区" },
                  model: {
                    value: _vm.form.area_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "area_id", $$v)
                    },
                    expression: "form.area_id",
                  },
                },
                _vm._l(_vm.areaSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.title },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "bill_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { multiple: "", clearable: "", placeholder: "类型" },
                  model: {
                    value: _vm.form.bill_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bill_type", $$v)
                    },
                    expression: "form.bill_type",
                  },
                },
                _vm._l(_vm.leixingList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "from" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "客户来源" },
                  model: {
                    value: _vm.form.from,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "from", $$v)
                    },
                    expression: "form.from",
                  },
                },
                _vm._l(_vm.laiyuanList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "vest_in" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "归属" },
                  model: {
                    value: _vm.form.vest_in,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vest_in", $$v)
                    },
                    expression: "form.vest_in",
                  },
                },
                _vm._l(_vm.guishuList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "keyword" } },
            [
              _c("el-input", {
                attrs: { placeholder: "企业ID或名称" },
                model: {
                  value: _vm.form.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "联系姓名或手机号" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "start_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "190px" },
                attrs: {
                  type: "date",
                  placeholder: "开始日期",
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.start_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "start_time", $$v)
                  },
                  expression: "form.start_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "end_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "190px" },
                attrs: {
                  type: "date",
                  placeholder: "结束日期",
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.end_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "end_time", $$v)
                  },
                  expression: "form.end_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "parent_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "推荐人姓名" },
                model: {
                  value: _vm.form.parent_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parent_name", $$v)
                  },
                  expression: "form.parent_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      _vm.form.pageSize = 10
                      _vm.form.pageNo = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.handleAdd } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [_c("el-button", [_vm._v("导出")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "190px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleCheck($index, row)
                          },
                        },
                      },
                      [_vm._v(" 查看合同 ")]
                    ),
                    row.status == 0 && row.can_check != 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleReview($index, row)
                              },
                            },
                          },
                          [_vm._v(" 审核 ")]
                        )
                      : _vm._e(),
                    row.status == 1 && row.can_check != 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleRefund($index, row)
                              },
                            },
                          },
                          [_vm._v(" 退款 ")]
                        )
                      : _vm._e(),
                    row.status == 0 && row.can_check != 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDelete($index, row)
                              },
                            },
                          },
                          [_vm._v(" 作废 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("check", { ref: "check", on: { refresh: _vm.fetchData } }),
      _c("add-order", { ref: "addOrder", on: { refresh: _vm.fetchData } }),
      _c("return-money", {
        ref: "returnMoney",
        on: { refresh: _vm.fetchData },
      }),
      _c("review", { ref: "review", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }