var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-autocomplete",
        {
          staticClass: "inline-input",
          attrs: {
            "popper-class": _vm.popperClass,
            "fetch-suggestions": _vm.querySearch,
            "value-key": "dealer_name",
            placeholder: "请输入经销商名称、简拼",
            disabled: _vm.disab,
            "trigger-on-focus": false,
          },
          on: { select: _vm.handleSelect, input: _vm.handleInput },
          model: {
            value: _vm.kw,
            callback: function ($$v) {
              _vm.kw = $$v
            },
            expression: "kw",
          },
        },
        [
          _vm.search
            ? _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "suffix" },
                on: { click: _vm.iconClick },
                slot: "suffix",
              })
            : _vm._e(),
        ]
      ),
      _c("choose-client", {
        ref: "chooseClient",
        on: { "change-client": _vm.setInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }