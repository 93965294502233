var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "软件服务合同",
            visible: _vm.showDialog,
            width: "800px",
            top: "8vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "orderTest-container",
            },
            [
              _c("order-status", { attrs: { status: _vm.status } }),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-position": "right",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "甲方:", prop: "dealer_name_jia" } },
                    [_vm._v(" " + _vm._s(_vm.form.dealer_name_jia) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "乙方:", prop: "dealer_name_yi" } },
                    [_vm._v(" " + _vm._s(_vm.form.dealer_name_yi) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容:", prop: "content" } },
                    [_vm._v(" " + _vm._s(_vm.form.content) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.list } },
                _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: "",
                      align: "center",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px", "text-align": "right" } },
            [
              _c("el-button", { attrs: { type: "primary" } }, [_vm._v("打印")]),
              _c("el-button", { attrs: { type: "warning" } }, [_vm._v("导出")]),
              _vm.statusid == 1
                ? _c("el-button", { on: { click: _vm.handleReturn } }, [
                    _vm._v("退款"),
                  ])
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
          _c("return-money", {
            ref: "returnMoney",
            on: { refresh: _vm.indexRefresh },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }