<template>
  <div class="wrapper">
    <el-autocomplete
      v-model="kw"
      class="inline-input"
      :popper-class="popperClass"
      :fetch-suggestions="querySearch"
      value-key="dealer_name"
      placeholder="请输入经销商名称、简拼"
      :disabled="disab"
      :trigger-on-focus="false"
      @select="handleSelect"
      @input="handleInput"
    >
      <i
        v-if="search"
        slot="suffix"
        class="el-input__icon el-icon-search"
        @click="iconClick"
      ></i>
      <!-- <template slot-scope="{ item }">
        <div class="wrap">
          <div class="name">
            {{ item.cust_name }}
          </div>
          <div class="addr">{{ item.boss }} {{ item.mobile }}</div>
        </div>
      </template> -->
    </el-autocomplete>
    <!-- <el-button
      icon="el-icon-search"
      circle
      class="icon-btn"
      @click="iconClick"
    ></el-button> -->
    <choose-client ref="chooseClient" @change-client="setInput"></choose-client>
  </div>
</template>
<script>
  import { formalUserList } from '@/api/admin/userManagement'
  import ChooseClient from './chooseClient'

  export default {
    name: 'ClientSearch',
    components: {
      ChooseClient,
    },
    props: {
      keyword: {
        type: [String, Number],
        default: () => '',
      },
      search: {
        type: Boolean,
        default: () => true,
      },
      disab: {
        type: Boolean,
        default: () => false,
      },
      popperClass: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {
        res: [],
        kw: this.keyword,
        restaurants: [],
      }
    },
    computed: {},
    watch: {
      kw(val) {
        if (val) {
          this.kw = val
          this.$emit('select-val', val)
        }
      },
    },

    created() {
      this.fetchData()
    },
    methods: {
      async fetchData(ky = '') {
        let { data, code, msg } = await formalUserList({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
        })
        console.log('123')
        console.log(data)
        if (code == 200) {
          this.res = data.rows
          return data.rows
        } else {
          this.$message.error(msg)
        }
      },
      // fetchData(kw = '') {
      //   getClientSelect({ pageNo: 1, pageSize: -1, keyword: kw }).then(
      //     (res) => {
      //       if (res.code == 200) {
      //         console.log(this.restaurants)
      //         this.restaurants = res.data
      //         return res.data
      //       } else {
      //         this.$message.error(res.msg)
      //       }
      //     }
      //   )
      // },
      iconClick() {
        console.log('click search')
        this.$refs['chooseClient'].showDialog = true
      },
      querySearch(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            let a = val.rows
            cb(this.res)
          })
        }

        // var restaurants = this.restaurants
        // var results = queryString
        //   ? restaurants.filter(this.createFilter(queryString))
        //   : restaurants
        // 调用 callback 返回建议列表的数据
      },
      // createFilter(queryString) {
      //   return (state) => {
      //     return (
      //       state.cust_name.toLowerCase().indexOf(queryString.toLowerCase()) ===
      //       0
      //     )
      //   }
      // },
      handleInput(val) {
        // this.$emit('select-val', val)
        // this.$emit('input-mine', val)
        // this.$emit('clear-input')
        if (val == '') {
          this.$emit('select-id', '')
          this.$emit('select-val-alldata', '')
        } else {
          this.$emit('select-id', -1)
          this.$emit('select-val-alldata', '')
        }
      },
      handleSelect(val) {
        console.log(val)
        this.$emit('select-val', val.cust_name)
        this.$emit('select-id', val.id)
        this.$emit('select-val-alldata', val)
      },
      setInput(val) {
        this.kw = val.dealer_name
        this.$emit('select-val', val.cust_name)
        this.$emit('select-id', val.id)
        this.$emit('select-val-alldata', val)
      },
      resetForm() {
        this.kw = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrap {
    display: flex;
    justify-content: space-between;
    .name {
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
