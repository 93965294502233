<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="area_id">
        <el-select
          v-model="form.area_id"
          clearable
          style="width: 140px"
          placeholder="地区"
        >
          <el-option
            v-for="(i, idx) in areaSelect"
            :key="idx"
            :value="i.id"
            :label="i.title"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="bill_type">
        <el-select
          v-model="form.bill_type"
          multiple
          clearable
          style="width: 140px"
          placeholder="类型"
        >
          <el-option
            v-for="(i, idx) in leixingList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="from">
        <el-select
          v-model="form.from"
          clearable
          style="width: 140px"
          placeholder="客户来源"
        >
          <el-option
            v-for="(i, idx) in laiyuanList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="vest_in">
        <el-select
          v-model="form.vest_in"
          clearable
          style="width: 140px"
          placeholder="归属"
        >
          <el-option
            v-for="(i, idx) in guishuList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="keyword">
        <el-input v-model="form.keyword" placeholder="企业ID或名称"></el-input>
      </el-form-item>

      <el-form-item label="" prop="name">
        <el-input v-model="form.name" placeholder="联系姓名或手机号"></el-input>
      </el-form-item>

      <el-form-item label="" prop="status">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="start_time">
        <el-date-picker
          v-model="form.start_time"
          type="date"
          placeholder="开始日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="width: 190px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="end_time">
        <el-date-picker
          v-model="form.end_time"
          type="date"
          placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="width: 190px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="parent_name">
        <el-input
          v-model="form.parent_name"
          placeholder="推荐人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="success" @click="handleAdd">添加</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button>导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="190px"
      >
        <!-- can_check =0，操作里去掉退款、审核、作废 -->
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看合同
          </el-button>
          <el-button
            v-if="row.status == 0 && row.can_check != 0"
            type="text"
            @click.native.prevent="handleReview($index, row)"
          >
            审核
          </el-button>
          <el-button
            v-if="row.status == 1 && row.can_check != 0"
            type="text"
            @click.native.prevent="handleRefund($index, row)"
          >
            退款
          </el-button>
          <el-button
            v-if="row.status == 0 && row.can_check != 0"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            作废
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <check ref="check" @refresh="fetchData"></check>
    <add-order ref="addOrder" @refresh="fetchData"></add-order>
    <return-money ref="returnMoney" @refresh="fetchData"></return-money>
    <review ref="review" @refresh="fetchData"></review>
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    orderSelectList,
    areaSelectList,
    orderTableData,
    deleteOrder,
  } from '@/api/admin/userManagement'
  import Check from './components/check'
  import AddOrder from './components/addOrder'
  import ReturnMoney from './components/returnMoney'
  import Review from './components/review'
  export default {
    name: 'OrderManagementAdmin',
    components: { Check, AddOrder, ReturnMoney, Review },
    data() {
      return {
        loading: false,
        leixingList: [],
        laiyuanList: [],
        guishuList: [],
        areaSelect: [],
        // 0待审1已审2作废 状态
        statusList: [
          {
            id: '0',
            name: '待审',
          },
          {
            id: '1',
            name: '已审',
          },
          {
            id: '2',
            name: '作废',
          },
        ],
        form: {
          pageSize: 10,
          pageNo: 1,
          sort: '', //
          order: '', //
          area_id: '', //
          bill_type: [], // 单据类型逗号分隔1签约2加端口3续期4网销升级5培训
          vest_in: '', // 归属
          status: '', // 状态 0待审1已审2作废
          from: '', // 客户来源
          keyword: '', // 企业ID或名称
          name: '', // 负责人姓名或手机号
          parent_name: '', // 推荐人名称
          start_time: '',
          end_time: '',
        },
        list: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '订单号',
          '企业名称',
          '联系人',
          '手机',
          '到期日期',
          '端口数',
          '网销门店上限',
          '归属',
          '类型',
          '金额',
          '下单日期',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '订单号',
            prop: 'bill_code',
            width: '140',
          },
          {
            order: 2,
            label: '企业名称',
            prop: 'dealer_name',
            width: '140',
          },
          {
            order: 3,
            label: '联系人',
            prop: 'dealer_boss',
            width: '',
          },
          {
            order: 4,
            label: '手机',
            prop: 'dealer_mobile',
            width: '110',
          },
          {
            order: 5,
            label: '到期日期',
            prop: 'cur_end',
            width: '110',
          },
          {
            order: 6,
            label: '端口数',
            prop: 'port_add',
            width: '',
          },
          {
            order: 7,
            label: '网销门店上限',
            prop: 'cur_mall_limit',
            width: '',
          },
          {
            order: 8,
            label: '归属',
            prop: 'vest_name',
            width: '',
          },
          {
            order: 9,
            label: '类型',
            prop: 'bill_type_text',
            width: '',
          },
          {
            order: 10,
            label: '金额',
            prop: 'total_amount',
            width: '',
          },
          {
            order: 11,
            label: '下单日期',
            prop: 'create_at',
            width: '110',
          },
          {
            order: 12,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.initSelect()
      this.initAreaSelect()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let form = JSON.parse(JSON.stringify(this.form))
        form.bill_type =
          form.bill_type.length == 0 ? '' : form.bill_type.join(',')
        let { data, totalCount } = await orderTableData(form)
        console.log(data)
        this.total = totalCount
        this.list = data
        this.loading = false
      },

      initSelect() {
        // 类型来源归属
        orderSelectList().then((res) => {
          this.leixingList = res.data.bill_type
          this.laiyuanList = res.data.from
          this.guishuList = res.data.staff
        })
      },
      async initAreaSelect() {
        let { data } = await areaSelectList()
        this.areaSelect = data
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleAdd() {
        this.$refs.addOrder.showDialog = true
      },

      handleCheck(index, row) {
        this.$refs.check.id = row.id
        this.$refs.check.statusid = row.status
        let status = ''
        if (row.status == 0) {
          status = 'dsh'
        } else if (row.status == 1) {
          status = 'ysh'
        } else if (row.status == 2) {
          status = 'yzf'
        } else if (row.status == 3) {
          status = 'ytk'
        }
        // 0待审1已审2作废 状态
        this.$refs.check.status = status
        this.$refs.check.showDialog = true
      },
      handleReview(index, row) {
        this.$refs.review.id = row.id
        this.$refs.review.showDialog = true
      },
      handleRefund(index, row) {
        console.log(row)
        this.$refs.returnMoney.form.id = row.id
        this.$refs.returnMoney.showDialog = true
      },
      handleDelete(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteOrder({
              id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
